const defaults = {
  breaks: false,
  gfm: true,
  baseURL: null,
  openLinksInNewTab: true,
  langPrefix: 'language-',
  renderer: null,
};

export default defaults;
